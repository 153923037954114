.barometer {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
}

.container {
    height: 100%;
    width: 100%;
    position: relative;
}
