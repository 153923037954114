.container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    container-type: size;
    align-items: center;
    justify-content: center;;
}

.base {
    left: 0;
    top: 0;
    z-index: 0;  
}

.cutout {
    position: absolute;
    z-index: 2;
}

.midlayer {
    position: absolute;
    background: repeating-conic-gradient(#808080 0% 25%, white 0% 50%)      50% / 4cqmin 4cqmin;
    z-index: 1;
}

.hide {
    opacity: 0;
    transition: opacity 2s ease-in;
}

.show {
    opacity: 1;
    transition: opacity 2s ease-in;
}